@import '../base/mixins';

@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?5msltj') format('truetype'),
    url('fonts/icomoon.woff?5msltj') format('woff'),
    url('fonts/icomoon.svg?5msltj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-complete .path1:before {
  content: "\e908";
  color: rgb(97, 162, 32);
  font-style: normal;
}
.icon-complete .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  font-style: normal;
}
.icon-play:before {
  content: "\e907";
  color: #fff;
}
.icon-chat .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.icon-chat .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(239, 170, 155);
}
.icon-cross:before {
  content: "\e902";
  color: #fff;
}
.icon-eye:before {
  content: "\e903";
  color: #fff;
}
.icon-left-arrow:before {
  content: "\e904";
}
.icon-lock:before {
  content: "\e905";
}
.icon-logo:before {
  content: "\e906";
  color: #fff;
}


@include font-face('Helvetica Neue', helveticaNeueLight, 300, normal);
@include font-face('Helvetica Neue', helveticaNeueMedium, 500, normal);
@include font-face('Helvetica Neue', helveticaNeueBold, 700, normal);
