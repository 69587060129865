@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.ttf?5msltj") format("truetype"), url("fonts/icomoon.woff?5msltj") format("woff"), url("fonts/icomoon.svg?5msltj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-complete .path1:before {
  content: "\e908";
  color: #61a220;
  font-style: normal; }

.icon-complete .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: white;
  font-style: normal; }

.icon-play:before {
  content: "\e907";
  color: #fff; }

.icon-chat .path1:before {
  content: "\e900";
  color: white; }

.icon-chat .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: #efaa9b; }

.icon-cross:before {
  content: "\e902";
  color: #fff; }

.icon-eye:before {
  content: "\e903";
  color: #fff; }

.icon-left-arrow:before {
  content: "\e904"; }

.icon-lock:before {
  content: "\e905"; }

.icon-logo:before {
  content: "\e906";
  color: #fff; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/helveticaNeueLight.eot");
  src: url("fonts/helveticaNeueLight.eot?#iefix") format("embedded-opentype"), url("fonts/helveticaNeueLight.woff2") format("woff"), url("fonts/helveticaNeueLight.woff") format("woff"), url("fonts/helveticaNeueLight.ttf") format("truetype"), url("fonts/helveticaNeueLight.svg#Helvetica Neue") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/helveticaNeueMedium.eot");
  src: url("fonts/helveticaNeueMedium.eot?#iefix") format("embedded-opentype"), url("fonts/helveticaNeueMedium.woff2") format("woff"), url("fonts/helveticaNeueMedium.woff") format("woff"), url("fonts/helveticaNeueMedium.ttf") format("truetype"), url("fonts/helveticaNeueMedium.svg#Helvetica Neue") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/helveticaNeueBold.eot");
  src: url("fonts/helveticaNeueBold.eot?#iefix") format("embedded-opentype"), url("fonts/helveticaNeueBold.woff2") format("woff"), url("fonts/helveticaNeueBold.woff") format("woff"), url("fonts/helveticaNeueBold.ttf") format("truetype"), url("fonts/helveticaNeueBold.svg#Helvetica Neue") format("svg");
  font-weight: 700;
  font-style: normal; }
